/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/components/fonts.scss'
import './src/components/colors.scss'
import './src/components/global.scss'

import React from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
}
