import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEN from './src/locales/en/translation.json'
import translationDE from './src/locales/de/translation.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    de: {
      translation: translationDE,
    },
    // Add more languages here
  },
  lng: 'en', // default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
